import React from 'react'
import PropTypes from 'prop-types'
import { Global } from '@emotion/core'
import { ApolloProvider } from '@apollo/react-hooks'
import '@dqp/common/styles/app.scss'
import globals from '@dqp/common/styles/index'
import { apolloClient } from '#lib/Apollo'
import { UserProvider } from '#context/UserContext'
import ErrorBoundary from '#components/ErrorBoundary'

export const wrapRootElement = ({ element }) => (
  <>
    <Global styles={globals} />
    <ApolloProvider client={apolloClient}>
      <UserProvider>
        <ErrorBoundary>{element}</ErrorBoundary>
      </UserProvider>
    </ApolloProvider>
  </>
)

wrapRootElement.propTypes = {
  element: PropTypes.any.isRequired,
}
