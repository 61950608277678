import {
  BlueShieldOnly,
  GreenShieldOnly,
  GreyShieldOnly,
} from '@dqp/common/components/icons'

const routes = {
  home: {
    to: `${process.env.STATIC_SITE_ENDPOINT}/`,
    external: true,
  },
  about: {
    to: `${process.env.STATIC_SITE_ENDPOINT}/about/`,
    external: true,
  },
  advice: {
    to: `${process.env.STATIC_SITE_ENDPOINT}/advice/`,
    external: true,
  },
  contact: {
    to: `${process.env.STATIC_SITE_ENDPOINT}/contact/`,
    external: true,
  },
  faq: {
    to: `${process.env.STATIC_SITE_ENDPOINT}/faq/`,
    external: true,
  },
  howitworks: {
    to: `${process.env.STATIC_SITE_ENDPOINT}/how-it-works/`,
    external: true,
  },
  pricing: {
    to: `${process.env.STATIC_SITE_ENDPOINT}/pricing/`,
    external: true,
  },

  privacy: {
    to: `${process.env.STATIC_SITE_ENDPOINT}/privacy/`,
    external: true,
  },
  terms: {
    to: `${process.env.STATIC_SITE_ENDPOINT}/terms/`,
    external: true,
  },
  app: { to: `/`, external: false },
  account: { to: `/account/`, external: false },
  subjectDashboard: {
    to: `/profile/:profileId/subscription/:subscriptionId/`,
    external: false,
    getPathFromParams: ({ profileId, subscriptionId }) =>
      `/profile/${profileId}/subscription/${subscriptionId}/`,
  },
  questionDashboard: {
    to: `/profile/:profileId/subscription/:subscriptionId/questions/`,
    external: false,
    getPathFromParams: ({ profileId, subscriptionId }) =>
      `/profile/${profileId}/subscription/${subscriptionId}/questions/`,
  },
  overviewDashboard: {
    to: `/profile/:profileId/`,
    external: false,
    getPathFromParams: ({ profileId }) => `/profile/${profileId}/`,
  },
  manageSubject: { to: `/manage-subject/`, external: false },
  signup: { to: `/signup/`, external: false },
  login: { to: `/login/`, external: false },
  resetpassword: {
    to: `/reset-password/`,
    external: false,
  },
  updatepassword: {
    to: `/update-password/`,
    external: false,
  },
  verifyemail: {
    to: `/verify-email/`,
    external: false,
  },
  privateNotFound: {
    to: `/404/`,
    external: false,
  },
  publicNotFound: {
    to: `/not-found/`,
    external: false,
  },
}

export const externalSites = {
  examPaperPlus: `https://exampapersplus.co.uk/`,
  preTestPlus: `https://pretestplus.co.uk/`,
  markSchemePlus: `https://exampapersplus.co.uk/mark-schemes/`,
  facebook: `https://www.facebook.com/exampapersplus/`,
  twitter: `https://twitter.com/exampapersplus`,
}

export const headerRoutes = [
  { ...routes.howitworks, text: `How it Works`, target: '_blank' },
  { ...routes.advice, text: `Advice`, target: '_blank' },
  { ...routes.pricing, text: `Pricing`, target: '_blank' },
  { ...routes.about, text: `About`, target: '_blank' },
  { ...routes.contact, text: `Contact`, target: '_blank' },
]

export const publicHamburgerMenuRoutes = [
  { to: routes.home.to, text: `Home`, target: '_blank' },
  ...headerRoutes,
  { to: routes.faq.to, text: `FAQs`, target: '_blank' },
]
export const accuntHamburgerMenuRoutes = [
  { to: routes.account.to, text: `My Account` },
  { to: routes.app.to, text: `Manage Subjects` },
]
export const externalLinks = [
  {
    to: externalSites.examPaperPlus,
    text: `Exam Papers Plus`,
    icon: BlueShieldOnly,
    external: true,
  },
  {
    to: externalSites.preTestPlus,
    text: `Pretest Plus`,
    icon: GreenShieldOnly,
    external: true,
  },

  {
    to: externalSites.markSchemePlus,
    text: `Mark Schemes Plus`,
    icon: GreyShieldOnly,
    external: true,
  },
]

export const footerRoutes = [
  {
    title: `Our Sites`,
    routeMap: externalLinks,
  },
  {
    title: `Company`,
    routeMap: [
      {
        to: routes.howitworks.to,
        text: `How it Works`,
        external: true,
      },
      { to: routes.advice.to, text: `Advice`, external: true },
      { to: routes.pricing.to, text: `Pricing`, external: true },
      { to: routes.about.to, text: `About`, external: true },
    ],
  },
  {
    title: `Help`,
    routeMap: [
      { to: routes.faq.to, text: `FAQs`, external: true },
      { to: routes.contact.to, text: `Contact`, external: true },
    ],
  },
]

export default routes
