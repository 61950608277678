/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import * as Sentry from '@sentry/browser'
import LogRocket from 'logrocket'
import { colors } from '@dqp/common/styles/utilities'
import Title from '@dqp/common/components/Title/Title'
import Button from '@dqp/common/components/Button/Button'
import { get } from 'lodash-es'
import Stack from './Stack/Stack'
import { UserContext } from '#context/UserContext'

const styles = css`
  background-color: ${colors.white};
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 900;
  top: 0;
  left: 0;
  .full-height {
    height: 100%;
  }
`

class ErrorBoundary extends React.Component {
  // eslint-disable-next-line react/static-property-placement
  static contextType = UserContext

  constructor(props) {
    super(props)
    this.state = { hasError: null, eventId: null }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    const { email, first_name, last_name } = get(
      this.context,
      'user.user',
      {},
    )
    LogRocket.getSessionURL(sessionURL => {
      Sentry.configureScope(scope => {
        // Send Logrocket session url to Sentry
        scope.setExtras({
          sessionURL,
          name: `${first_name} ${last_name}`,
        })
        if (email) scope.setUser({ email })
        scope.setExtras(errorInfo)
        const eventId = Sentry.captureException(error)
        this.setState({ eventId })
      })
    })
  }

  render() {
    const { email, first_name, last_name } = get(
      this.context,
      'user.user',
      {},
    )
    if (this.state.hasError) {
      return (
        <div css={styles}>
          <div className='container full-height'>
            <div className='row full-height align-items-center'>
              <Stack
                spacing={30}
                className='col-12 text-center d-flex flex-column align-items-center'
              >
                <Title color={colors.black} size='xxxxLarge'>
                  Something went wrong!
                </Title>
                <Button
                  size='large'
                  variant='black'
                  onClick={() => {
                    window.location.reload()
                  }}
                >
                  Reload
                </Button>
                {this.state.eventId && (
                  <Button
                    style={{ marginTop: -20 }}
                    underlineColor={colors.black}
                    onClick={() => {
                      Sentry.showReportDialog({
                        eventId: this.state.eventId,
                        user: {
                          name: `${first_name} ${last_name}`,
                          email,
                        },
                      })
                    }}
                  >
                    Report Issue
                  </Button>
                )}
              </Stack>
            </div>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.any.isRequired,
}
export default ErrorBoundary
