import React from 'react'
import { css } from '@emotion/core'
import PropTypes from 'prop-types'
import { spacer } from '@dqp/common/styles/utilities'

const styles = {
  stack: ({ spacing }) => css`
    & > [data-stack-child] {
      ${spacer[`mrB${spacing}`]};
      :last-child {
        ${spacer.mrB0};
      }
    }
  `,
}

const Stack = ({ children, spacing, className }) => {
  const childArray = React.Children.map(children, child =>
    React.isValidElement(child)
      ? React.cloneElement(child, {
          'data-stack-child': true,
        })
      : child,
  )

  return (
    <div className={className} css={styles.stack({ spacing })}>
      {childArray}
    </div>
  )
}

Stack.propTypes = {
  /**
   * any valid spacing token from @dqp/common/styles/tokens/spacings.js
   */
  spacing: PropTypes.number.isRequired,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
}

Stack.defaultProps = {
  className: '',
}

export default Stack
