import React from 'react'
import { css } from '@emotion/core'

export const baseImgixURL = `${process.env.IMGIX_ENDPOINT}/`
export const reactRevealDuration = 500 // ms
export const reactRevealDistance = '50%'

export const apiStatuses = {
  forgotPassword: {
    EMAIL_NOT_SENT: 'EMAIL_NOT_SENT',
    EMAIL_SENT: 'EMAIL_SENT',
  },
  register: {
    MUST_VERIFY_EMAIL: 'MUST_VERIFY_EMAIL',
    SUCCESS: 'SUCCESS',
  },
  updateForgottenPassword: {
    PASSWORD_NOT_UPDATED: 'PASSWORD_NOT_UPDATED',
  },
  updatePassword: {
    PASSWORD_UPDATED: 'PASSWORD_UPDATED',
  },
}

export const authErrorCategories = {
  authentication: 'authentication',
}

export const subscriptionTypes = {
  FREE: 'FREE',
  MONTHLY: 'MONTHLY',
  YEARLY: 'YEARLY',
}

export const stripeStatus = {
  active: 'active',
  trialing: 'trialing',
  canceled: 'canceled',
  incomplete: 'incomplete',
  incomplete_expired: 'incomplete_expired',
  unpaid: 'unpaid',
  past_due: 'past_due',
}

export const restEndpoints = {
  setupIntent: `${process.env.REST_ENDPOINT}/api/user/stripe-subscriptions/setup-intent`,
  paymentMethods: `${process.env.REST_ENDPOINT}/api/user/stripe-subscriptions/payment-methods`,
  defaultPaymentMethod: `${process.env.REST_ENDPOINT}/api/user/stripe-subscriptions/default-payment-method`,
}

export const apiErrorCategories = {
  forbidden: 'forbidden',
  unauthorized: 'unauthorized',
}

export const defaultNoteField =
  'Read the instructions below and then select the correct answer choice from the options provided.'

export const feedbackDismissDuration = 10000 // 10sec

export const storageKeys = {
  user: 'user',
  keepLoggedIn: 'keepLoggedIn',
}

export const pricingTexts = {
  free: <strong>Free trial</strong>,
  monthly: (
    <span>
      <strong>£15/month</strong> <br /> 2 questions with video
      tutorials and explanations released every day.
    </span>
  ),
  yearly: (
    <span>
      <strong>£8.25/month (billed annually at £99/year)</strong>{' '}
      <span> - BEST VALUE!</span> <br />
      Immediate access to all 770 questions with video tutorials.
    </span>
  ),
}
export const pricingBandsStyles = css`
  @supports (display: grid) {
    display: grid !important;
    grid-auto-rows: 1fr;
  }
`

export const cookieDuration = 1 // in day
export const companyEmail = `info@dailyquestplus.co.uk`
