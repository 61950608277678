import React, {
  useContext,
  createContext,
  useState,
  useEffect,
} from 'react'
import PropTypes from 'prop-types'
import Cookies from 'js-cookie'
import LogRocket from 'logrocket'
import { get } from 'lodash-es'
import { useDeepCompareEffect } from 'react-use'
import { storageKeys } from '#globals/constants'

export const UserContext = createContext({
  user: {},
  setUser: () => {},
})

export const useUserContext = () => {
  const user = useContext(UserContext)
  return user
}

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(
    () => Cookies.getJSON(storageKeys.user) || null,
  )
  useDeepCompareEffect(() => {
    if (user) {
      const { id, email, first_name, last_name } = get(
        user,
        'user',
        {},
      )

      LogRocket.identify(id, {
        name: `${first_name} ${last_name}`,
        email,
      })
    }

    if (Cookies.getJSON(storageKeys.keepLoggedIn)) {
      Cookies.set(storageKeys.user, user, {
        expires: 365,
      })
    } else {
      Cookies.set(storageKeys.user, user, { expires: 1 })
    }
  }, [user])

  useEffect(() => {
    // this is to check if user exists, cuz if cookie gets expired then browser automatically deletes it
    // which doesnt update App's internal state
    // therefore to synchronize browser state with App state we check user and accordingly set user to null
    const intervalId = setInterval(() => {
      if (Cookies.getJSON(storageKeys.user)) {
        return
      }
      setUser(null)
    }, 10000)
    return () => clearInterval(intervalId)
  }, [])

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  )
}

UserProvider.propTypes = {
  children: PropTypes.any.isRequired,
}
