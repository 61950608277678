import ApolloClient, { InMemoryCache } from 'apollo-boost'
import { get } from 'lodash-es'
import Cookies from 'js-cookie'
import { navigate } from '@reach/router'
import { apiErrorCategories } from '#globals/constants'
import routes from '#globals/routes'
import getErrorCategory from '#utils/getErrorCategory'

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  uri: process.env.GRAPHQL_ENDPOINT,
  onError: error => {
    const { graphQLErrors, networkError } = error
    const category = getErrorCategory(error)

    if (category === apiErrorCategories.forbidden) {
      navigate(routes.verifyemail.to)
    }

    if (category === apiErrorCategories.unauthorized) {
      navigate(routes.privateNotFound.to)
    }

    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.info(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      )
    if (networkError) console.info(`[Network error]: ${networkError}`)
  },
  resolvers: {
    SubscriptionData: {
      questionsWithNumberAttached: parent => {
        const result = parent.questions.map((question, i) => ({
          ...question,
          question: { ...question.question, number: i + 1 },
        }))
        return result
      },
    },
    AnsweredQuestionResponse: {
      optionsListWithChosenOptions: parent => {
        return parent.question.options.map(option => ({
          ...option,
          was_chosen:
            parent.attempt.answered_options.findIndex(
              el => el.id === option.id,
            ) !== -1,
        }))
      },
    },
  },
  request: operation => {
    const user = Cookies.getJSON('user') || null
    const token = get(user, 'access_token')
    operation.setContext({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  },
})
